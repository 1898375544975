import {ParametrageWay, SouscriptionResource} from "@core/backend/models/souscription";

export interface SouscriptionState {
  souscriptionEnCours: SouscriptionResource<ParametrageWay> | null;
}


export class StateFactory {
  public static createInitialSouscriptionState(): SouscriptionState {
    return {
      souscriptionEnCours: null,
    }
  }
}
