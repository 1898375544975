import {
  StateFactory
} from "./souscription.state";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {DeclenchementPaiementState} from "@core/backend/models/souscription/declenchementPaiementState";
import { etapeValidationEmailActions } from "../etape-validation-email/store/etape-validation-email.actions";
import { etapeConfirmationEmailEnvoyeActions } from "../etape-confirmation-email-envoye/store/etape-confirmation-email-envoye.actions";
import {
  etapeRecapitulatifSouscriptionActions
} from "@way/app/souscription/etape-recapitulatif-souscription/store/etape-recapitulatif-souscription.actions";
import {recueilActions} from "@way/app/souscription/recueil/store/recueil.actions";
import {etapePaiementActions} from "@way/app/paiement/etape-paiement/store/etape-paiement.actions";

export const initialState = StateFactory.createInitialSouscriptionState();

export const souscriptionFeature = createFeature({
  name: 'souscription',
  reducer: createReducer(initialState,

    on(etapeValidationEmailActions.aPIAIndiqueAvoirEnvoyeEmailDeValidation, (state, action) => {
      return {
        ...state,
        souscriptionEnCours: { ...action.souscription },
      };
    }),
    on(etapeConfirmationEmailEnvoyeActions.aPIAFourniSouscription, recueilActions.aPIAFourniSouscription, (state, action) => {
      return {
        ...state,
        souscriptionEnCours: { ...action.souscription },
      };
    }),

    on(etapePaiementActions.aPIAReponduSuiteADeclenchementPaiement, (state, action)=>{
      if(action.payload.type === DeclenchementPaiementState.Succes){
        return {
          ...state,
          souscriptionEnCours:action.payload.souscriptionModifieeSuiteAuPaiement
        }
      }
      return state;
    }),

    on(etapeRecapitulatifSouscriptionActions.apiARenvoyeOk, (state, action) => ({
      ...state,
      souscriptionEnCours: { ...action.souscription },
    })),
  ),
  extraSelectors:({selectSouscriptionEnCours})=>({
    selectEmailContactInitial: createSelector(selectSouscriptionEnCours, (souscription) => souscription?.emailContactInitial ?? null),
    selectCodePaysDomicile: createSelector(selectSouscriptionEnCours, (souscription) => souscription?.parametrage.codePaysDomicile ?? null),
    selectSouscriptionId: createSelector(selectSouscriptionEnCours, (souscription) => souscription!.id),
  })
});
